.kyc-modal-title {
  font-size: 36px;
  color: #2c5282;
}

.kyc-modal-user-details {
  color: #9a9a9b;
  font-size: 24px;
}

.kyc-modal-profile-button {
  margin-left: 50px;
  width: 170px !important;
  border-radius: 10px !important;
}

.kyc-modal-docs-info {
  width: 680px !important;
  height: 56px;
  background: #dce6f0;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
}

.kyc-modal-docs-info-details {
  display: flex;
  align-items: center;
}

.kyc-modal-docs-info-title {
  font-size: 22px;
  color: #8c8c8d;
}

.kyc-modal-docs-info-title2 {
  font-size: 22px;
  color: #8c8c8d;
  margin-left: 30px;
}

.kyc-modal-docs-info-value {
  font-size: 22px;
  color: #2c5282;
}

.kyc-modal-download-button {
  /* width: 56px;
  height: 56px; */

  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  font-size: 54px;
  color: #2a4365;
}

.kyc-modal-selfie-row {
  display: flex;
  justify-content: space-around;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.kyc-modal-selfie-col {
  padding: 0 !important;
}

.kyc-modal-selfie {
  width: 300px;
  margin-bottom: 5px;
}

.kyc-modal-selfie:active {
  transform: scale(2);
}

.kyc-modal-selfie-type {
  font-size: 16px;
  font-weight: 600;
  color: #2c5282;
}

.kyc-modal-note{
  padding: 0 !important;
  font-size: 16px;
  color: #2c5282;
}