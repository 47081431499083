.dashboard-title {
  font-weight: 700;
  font-size: 20px;
}

.dashboard-statistics-user-box {
  padding: 5px;
}

.dashboard-statistics-user-box-container {
  border: 2px solid black;
  padding: 5px;
  border-radius: 10px;
}

.dashboard-text {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
  color: #2c5282;
}

.dashboard-download-button {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  font-size: 28px;
  color: #2a4365;
  float: right;
}

.dashboard-download-text {
  color: #2a4365;
  float: right;
  margin-right: 10px;
}

.dashboard-analytics-box {
  /* padding: 30px; */
  margin: 0;
}

.dashboard-analytics-user-details-box {
  margin-bottom: 20px;
  display: flex !important;
  justify-content: space-between;
}

.dashboard-analytics-pie-box {
  padding: 10px;
  border-radius: 10px;
  height: 400px;
  /* width: 50%; */
}

.pie-title {
  font-weight: 600;
  font-size: 18px;
}

.dashboard-height {
  height: 400px;
}

.dashboard-graph-outer-box {
  height: 640px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
