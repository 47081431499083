.update-enabler-page-container {
  color: #2c5282;
}

.update-enabler-type-title {
  font-weight: bold;
  font-size: 18px;
}
.modla-userImage{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modla-userImage-img{
  width: 100%;
}
.update-enabler-save-button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#upload_button {
  display: inline-block;
}
#upload_button input[type="file"] {
  display: none;
}

.update-enabler-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.update-enabler-upload-preview {
  height: 100px;
  width: 100px;
  border: 1px solid black;
}

.update-enabler-clear-button {
  padding: 0 10px !important;
  background: white !important;
}

.update-enabler-clear-button:hover {
  cursor: pointer;
}

.update-enabler-master-enabler-name {
  margin-top: 8px;
}

.update-enabler-master-enabler-name-empty {
  color: rgb(245, 3, 3);
}

.update-enabler-span-button {
  color: white;
  background: #2a4365;
  border-radius: 0.25rem;
  text-align: center;
  padding: 6px 12px;
}

.update-enabler-span-button:hover {
  cursor: pointer;
}
