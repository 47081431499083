.service-grid-title {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  color: #2c5282;
}

.service-grid-label {
  font-weight: 600;
  font-size: 16px;
  color: #2c5282;
}

.service-grid-button-col {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.service-grid-resize {
  color: #0a65ff;
  text-decoration: underline;
}

.service-grid-resize:hover {
  cursor: pointer;
}

.service-grid-infinity-div {
  background: white;
  padding: 10px 30px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: inset 0px -1px 0px #e4e4e7;
  margin-bottom: 10px;
}

.service-grid-logo {
  color: #2a4365;
  margin-right: 8px;
}

.service-grid-close-logo {
  color: #2a4365;
  height: 25px !important;
  width: 25px !important;
}

.service-grid-close-logo:hover {
  cursor: pointer;
}

.service-grid-modal-header {
  color: #2a4365;
  margin: 0px auto;
  font-weight: 600 !important;
}

.service-grid-save-button {
  margin-top: 20px;
  float: right;
}

.service-grid-close-button:hover {
  cursor: pointer;
}

.service-grid-clear-button:hover {
  cursor: pointer;
}

.service-grid-clear-button {
  padding: 0 10px !important;
  background: white !important;
}

.service-grid-buttons-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px !important;
}

.service-grid-button {
  width: 150px !important;
  margin-left: 15px;
}

.service-grid-enablers-links-container {
  margin-top: 60px !important;
}

.service-grid-enablers-links {
  text-decoration: none;
  color: #2c5282;
  font-weight: 600;
}

.service-grid-enablers-span1 {
  margin-right: 20px;
}
