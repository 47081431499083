.kyc-title {
  font-weight: 700;
  font-size: 20px;
}

.kyc-labels {
  color: #2c5282;
  font-size: 16px;
  font-weight: 600;
}

.kyc-requests-clear-button {
  padding: 0 10px !important;
  background: white !important;
}

.kyc-requests-clear-button:hover {
  cursor: pointer;
}

.kyc-requests-date-box {
  width: 30px !important;
}

.kyc-requests-search-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.kyc-requests-search-button {
  background: #2a4365 !important;
  height: 38px;
  width: 150px !important;
  border: 1px solid white !important;
}

.kyc-infinity-div {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1338px) {
  .kyc-infinity-div {
    justify-content: center !important;
  }

  .kyc-requests-search-button-container {
    margin-top: 20px !important;
  }
}
