.subscribers-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribers-table {
  margin-top: 20px !important;
}

.subscribers-table-actions:hover {
  cursor: pointer;
}

.subscribers-table-actions {
  color: #2c5282;
}

.subscribers-label {
  color: #2c5282;
  font-weight: 600;
  font: size 16px;
}

.subscribers-count-box {
  display: flex;
  justify-content: space-between;
  margin-top: 40px !important;
}

.subscribers-clear-button {
  padding: 0 10px !important;
  background: white !important;
}

.pending-search-button:hover {
  cursor: pointer;
}

.pending-search-button-container {
  float: right;
}

.pending-search-button {
  background: #2a4365 !important;
  height: 38px;
  width: 100px !important;
  border: 1px solid white !important;
}

.pending-search-button-active {
  background: #6ba8fd !important;
  height: 38px;
  width: 100px !important;
  border: 1px solid white !important;
}
