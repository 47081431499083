.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2a4365;
  padding: 10px;
  position: fixed;
  z-index: 1;
  height: 10vh;
}

.navbar-title {
  font-family: "Bungee Inline", cursive;
  font-size: 40px;
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar-logo {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.navbar-title-1 {
  color: white;
}

.navbar-title-2 {
  color: #26a6d1;
}

.navbar-link-text {
  color: white;
  text-decoration: none;
}

.navbar-title-link-text {
  text-decoration: none;
}
