.dashboard-graph-box {
  width: 50%;
  height: 320px;
  padding: 10px;
  border: 1px solid rgb(195, 194, 194);
  border-radius: 10px;
}

.dashboard-graph {
  height: 250px;
}

.dashboard-graph-title {
  font-size: 20px;
}
