.sidebar-container {
  background: #edf2f7;
  /* position: fixed;
  margin-top: 80px;
  width: 300px !important; */
  padding: 0 !important;
  width: 250px !important;
  margin-top: 10vh !important;
  position: fixed;
  height: 90vh;
}

.sidebar-logo {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}

.sidebar-link-container {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-top: 70px;
}

.sidebar-link-container-title {
  color: #4299e1;
}

.sidebar-link {
  display: flex;
  align-items: center;
  color: #2c5282;
  text-decoration: none;
  margin-bottom: 20px;
}

.sidebar-isActive-link {
  display: flex;
  align-items: center;
  color: #2c5282;
  text-decoration: none;
  margin-bottom: 20px;
  font-weight: 600;
}
