.kyc-details-card-container {
  margin: 0px !important;
  padding: 0px !important;
  width: 460px !important;
  height: 121px;
  margin-bottom: 15px !important;
  margin-right: 20px !important;
}

.kyc-details-card-row {
  width: 460px !important;
  height: 121px;
  background: #dce6f0;
  border: 1px solid rgba(44, 82, 130, 0.1);
  border-radius: 15px;
  margin: 0 !important;
  display: flex;
}

.kyc-details-card-col-indicator {
  height: 119px !important;
  width: 20px !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.kyc-details-card-col-pending-indicator {
  background: #b6c5d5;
}

.kyc-details-card-col-accepted-indicator {
  background: #00e132;
}

.kyc-details-card-col-rejected-indicator {
  background: #f30000;
}

.kyc-details-card-content {
  height: 121px;
  width: 380px !important;
  border-left: 1px solid rgba(44, 82, 130, 0.1);
  font-size: 16px;
  padding: 5px;
}

.kyc-details-card-logo-container {
  display: flex;
  align-items: flex-end;
  height: 121px;
  width: 50px !important;
  padding: 0 !important;
  padding-bottom: 15px !important;
}

*[data-tooltip] {
  position: relative;
}

*[data-tooltip]::after {
  content: attr(data-tooltip);

  position: absolute;
  bottom: -30px;
  right: -340px;
  width: fit-content;

  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .15s ease-in-out;
  -moz-transition: opacity .15s ease-in-out;
  -ms-transition: opacity .15s ease-in-out;
  -o-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;

  display: block;
  font-size: 12px;
  line-height: 16px;
  background: #1f3553;
  padding: 10px 5px;
  border-radius: 10px;
  border: 0px;
  color: white;
  font-weight: 900;
  letter-spacing: 2px;
  z-index: 9;

  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
}

*[data-tooltip]:hover::after {
  opacity: 1;
}

.kyc-details-card-logo {
  height: 40px;
  width: 40px;
}

.kyc-details-card-logo:hover {
  cursor: pointer;
}

.kyc-details-card-username {
  color: #2c5282;
  font-weight: 600;
}

.kyc-details-card-username-details {
  color: #7b7c7d;
  font-size: 14px;
}

.kyc-details-card-modal {
  border-radius: 15px !important;
}

.kyc-details-card-modal-button-container {
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.kyc-details-card-modal-close-button:hover {
  cursor: pointer;
}
