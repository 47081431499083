.week-day{
    padding: 8px;
    border-radius: 3px;
    margin-right: 10px;
    width: 50px;

    background-color: rgb(44,82,130);
    color: white;
    border: 1px solid red;
}
.week-day:hover{
    color: rgb(44,82,130);
    background-color: white;
}
.selected-day{
    padding: 8px;
    border-radius: 3px;
    margin-right: 10px;
    width: 50px;
    
    color: rgb(44,82,130);
    background-color: white;
}