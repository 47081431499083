.dashboard-label-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  /* background-color: #5b5de7; */
  background: linear-gradient(to right, #2a4365, #6a86af);
  color: white;
  padding: 10px;
}

.label-title {
  font-weight: 600;
}
