.login-parent-container {
  display: flex;
  height: 100vh;
  background: #edf2f7;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-container {
  width: 512px;
  background: white;
  border-radius: 10px;
  /* box-shadow: 1px 1px 1px 1px grey; */
}

.login-title {
  font-family: "Bungee Inline", cursive;
  font-size: 64px;
  display: flex;
  align-items: center;
}

.login-title-1 {
  color: #2a4365;
}

.login-title-2 {
  color: #26a6d1;
}

.login-form {
  width: 436px;
  margin: 30px auto;
}

.login-input {
  margin: 3px auto;
  background: #edf2f7 !important;
  height: 45px;
  /* box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white; */
}

.login-input-error {
  margin: 3px auto;
  background: #f88585 !important;
  height: 45px;
}

.login-label {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.login-bottom-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-forgot-password {
  color: #3182ce;
  text-decoration: none;
  font-size: 20px;
}

.login-model-note {
  font-size: 18px;
  text-align: justify;
  padding: 20px;
}

.login-modal-header {
  background: #e5e5e5 !important;
}

.login-modal-title {
  font-family: "Bungee Inline", cursive;
  font-size: 40px;
  display: flex;
  align-items: center;
}
