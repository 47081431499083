.subscriber-details-div {
  width: 275px;
  height: fit-content;
  padding: 14px;
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
  /* position: fixed; */
}

.flex-row-points {
  position: relative;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: #132e50;
  border-radius: 20px;
}
.copy-tag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white !important;
  background-color: #2a4365 !important;
}
.point-indiv {
  margin-right: 30px;
}
.path {
  padding-top: 10px;
}
.no-photos-subs {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.subscriber-details-image {
  width: 247px;
  height: 254px;
}
.scrollRow {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  height: 87px;
}
.scrollRow::-webkit-scrollbar {
  display: none;
}
.scrollRow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.subscriber-details-logo {
  color: #2a4365;
  margin-right: 8px;
  height: 20px !important;
  width: 20px !important;
}
.card-modal-imageClick {
  width: fit-content;
  border-radius: 10px;
}
.modal-body-imageClick img {
  width: 650px;
  height: 650px;
  margin: 0 auto;
}

.modal-body-imageClickFolder {
  height: fit-content;
  max-height: 900px;
  width: 100%;
}
.modal-body-imageClickFolder img {
  width: 50px;
  max-height: 500px;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 1px;
}
.CarouselFolder {
  min-height: 500px;
  width: 750px;
}

.subscriber-details-image-person {
  object-fit: cover;
  border-radius: 10px;
}
.subscriber-property-enabler-photo-div {
  margin-bottom: 0px;
  height: 85px;
}

.subscriber-property-report-div {
  margin-bottom: 0px;
  height: 85px;
}

.no-reports-subscriber {
  padding-bottom: 12px;
}

.subscriber-pdf {
}

.subscriber-pdf-regenerate {
  margin-left: 5px;
  height: 600px !important;
}

.subscriber-pdf-regenerate-icon {
  height: auto;
  margin-top: -60px !important;
}

.subscriber-property-enabler-photo {
  height: 80px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.subscriber-details-info-box {
  margin-top: 15px;
  color: #2c5282;
}

.subscriber-details-info-title {
  font-weight: 600;
  font-size: 19px;
}

.subscriber-details-info-detail {
  font-size: 14px;
}
.green-text {
  color: green;
  text-shadow: 1px 1px rgb(160, 214, 184);
}
.red-text {
  color: red;
  text-shadow: 1px 1px rgb(212, 151, 151);
}
.grey-text {
  color: grey;
}

.subscriber-details-info {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.subscriber-property-div {
  padding: 14px;
  background: white;
  border-radius: 10px;
  /* margin-left: 275px; */
}

.subscriber-details-property-box-title {
  font-weight: 600;
  font-size: 25px;
  color: #2c5282;
}

.subscriber-details-property-detail-box {
  background: #ecf4ff;
  padding-left: 12px !important;
  padding-right: 12px !important;
  margin: 0 !important;
  color: #2c5282;
  padding-bottom: 12px !important;
  border-radius: 10px;
}

.subscriber-details-property-detail-title {
  background: #2c5282;
  display: flex;
  align-items: center;
  height: 30px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
}

.subscriber-details-property-detail-type-box {
  margin-top: 10px !important;
}

.subscriber-details-property-detail-type-title {
  font-size: 16px;
  font-weight: 600;
}

.subscriber-details-property-detail-subscription-title {
  font-size: 20px;
  font-weight: 600;
}

.subscriber-property-initial-image {
  width: 100px;
  height: 75px;
  margin-right: 5px;
  margin-top: 5px;
}

.subscriber-property-enabler-photo-div {
  display: flex;
  text-align: center;
  cursor: pointer;
}

.subscriber-property-report-div {
  display: flex;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}
.subscriber-property-enabler-dateSelector-div {
  display: flex;
  text-align: center;
}

.subscriber-property-enabler-photo {
  margin-right: 10px;
  margin-top: 5px;
  width: 90px;
}

.subscriber-property-pagination-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px !important;
}

.subscriber-details-arrow-logo {
  color: #2a4365;
  margin-left: 15px;
  height: 20px !important;
  width: 20px !important;
}

.subscriber-details-arrow-logo:hover {
  cursor: pointer;
}

.subscriber-details-property-detail-enablers {
  display: flex;
  align-items: center;
}

.subscriber-details-property-detail-enablers-title {
  margin-right: 30px;
}

.subscriber-details-property-detail-select-enablers-buttons-box {
  display: flex;
  align-items: flex-end;
}
.btn-generateReport {
  background-color: #2c5282;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  margin-top: 20px;
  color: white;
}
.report-status {
  padding-top: 20px;
  color: #132e50;
  margin-left: 2px;
}
.grey {
  background-color: grey;
  color: white;
  padding: 8px 20px;
}

.anchor-text {
  text-decoration: none;
  color: #2c5282;
}
.anchor-text:hover {
  text-decoration: none;
  color: #2c5282;
}
.anchor-text:visited {
  text-decoration: none;
  color: #2c5282;
}
.dayPicker {
  position: relative;
}

.subsciber-schedule-row {
  margin-left: 0px !important;
}

.subsciber-schedule-box {
  border: 1px solid #2c5282;
}

.subsciber-schedule-box-date {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cursor-hover:hover {
  cursor: pointer;
}

.subscriber-reject-text {
  color: red;
  margin-top: 10px;
}

.subscriber-property-doc {
  height: 200px !important;
  width: 200px !important;
  margin-top: 30px !important;
}

.subscriber-property-doc-group {
  display: flex;
  align-items: center;
}
