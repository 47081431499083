.onboard-page-container {
  color: #2c5282;
}

.onboard-type-title {
  font-weight: bold;
  font-size: 18px;
}
.FormControl::-webkit-inner-spin-button, 
.FormControl::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.image-border-upload{
  border: 2px dashed #2c5282;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

.margin-top-30{
  margin-top: 100px;
}

.onboard-save-button-container {
  display: flex;
  align-items: flex-end;
}

#upload_button {
  display: inline-block;
}
#upload_button input[type="file"] {
  display: none;
}

.onboard-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.onboard-upload-preview {
  height: 100px;
  width: 100px;
  border: 1px solid black;
}

.onboard-clear-button {
  padding: 0 10px !important;
  background: white !important;
}

.onboard-clear-button:hover {
  cursor: pointer;
}

.onboard-master-enabler-name {
  margin-top: 8px;
}

.onboard-master-enabler-name-empty {
  color: rgb(245, 3, 3);
}

.onboard-note{
  padding: 0 !important;
  font-size: 16px;
  color: #2c5282;
}


/* loader styles */
.center-loader{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #2c5282;
  border-color: #2c5282 transparent #2c5282 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  25% {
    transform: rotate(450deg);
    animation-timing-function: cubic-bezier(0.115, 0.57, 0.355, 1);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  75% {
    transform: rotate(1350deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
